export const effects = [
  {
    i: 0,
    effect: `胆经裤线重叠行，分泌紊乱变神经；</br>嗜睡液汗疲倦态，腋窝肿胀后脑痛；</br>淋巴发炎局部胖，眼花目黄有增生；</br>`,
  },
  {
    i: 1,
    effect: `肝经对应胆经行，循环分泌掌控中；</br>肤色发青腰疼痛，眼圈发黑有痛经；</br>眼球发干眼屎多，肝火旺盛有罪行；</br>`,
  },
  {
    i: 2,
    effect: `胸到拇指为肺经，手臂内侧属阴经；</br>呼吸免疫掌控中，缺水敏感鼻不通；</br>体热出汗背有痘，干燥痰多下咽痛；</br>感冒发冷体内空；</br>`,
  },
  {
    i: 3,
    effect: `食指腋窝大肠经，手臂外侧属阳经；</br>消化神经掌控中，它要不痛腹胀痛；</br>便秘口干肩颈痛，体热痔疮加头痛；</br>`,
  },
  {
    i: 4,
    effect: `胃经位于胆经前，三指距离到经中；</br>口腔糜乱牙肿痛，口干口臭腹胀痛；</br>体热打嗝喜食冷，大便干燥且不通；</br>`,
  },
  {
    i: 5,
    effect: `胃经对应是脾经，免疫神经掌控中；</br>胃胀打嗝排气空，呕吐难耐肋下痛；</br>曲张平血低血压，风湿还有关节痛；</br>`,
  },
  {
    i: 6,
    effect: `心到小指为心经，手臂内侧属阴经；</br>循环系统掌控中，胸口沉闷与头痛；</br>心烦失眠也多梦，肩与前胸多疼痛；</br>目赤颧红口干燥，血液不良喜安静；</br>心事过多压力重；</br>`,
  },
  {
    i: 7,
    effect: `小指肩窝小肠经，手臂外侧属阳经；</br>消化神经掌控中，太阳耳部会疼痛；</br>经前腹胀后脑痛，后背肩胛至背痛；</br>`,
  },
  {
    i: 8,
    effect: `肾经对应膀胱经，泌尿骨骼掌控中；</br>小便发黄膀胱痛，尿路发炎讲卫生；</br>四肢无力后背痛，痔疮难坐肩颈痛；</br>`,
  },
  {
    i: 9,
    effect: `肝经后面是肾经，距离不远两指空；</br>分泌系统掌控中，尿量稀少还尿频；</br>眼袋眼皱足下冷，下肢肿胀善惊恐；</br>经前腰酸背又痛，脸上出斑心发惊；</br>记忆下降无睡梦，症状不通慢慢通；</br>`,
  },
  {
    i: 10,
    effect: `胸到中指心包经，手臂内侧属阴经；</br>分泌循环掌控中，循环差异血管病；</br>心跳过快还便秘，心烦目赤上肢痛；</br>`,
  },
  {
    i: 11,
    effect: `无名至肩三焦经，手臂外侧属阳经；</br>分泌循环掌控中，免疫下降忧郁症；</br>疲倦易得慢性病！`,
  },
];
