import React from "react";
import "./app.less";
import { Button } from "antd";
import WorkTable from "./pages/WorkTable/WorkTable";

function App() {
  return (
    <div className='container'>
      <WorkTable></WorkTable>
    </div>
  );
}

export default App;
