import React from "react";
import { FC } from "react";
import "./description.less";

interface IDescriptionProps {
  title: string;
  content: Element | string;
}

export const Description: FC<IDescriptionProps> = ({ title, content }) => {
  return (
    <div className="desc-wrap">
      <div className="desc-title">{title}：</div>
      <div className="desc-content">{content}</div>
    </div>
  );
};
