import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col } from "antd";
import dayjs from "dayjs";
import data from "../../assets/data/data.json";
import { effects as effectList } from "../../assets/data/effects.js";
import "./styles/work-table.less";
import { Description } from "../../components/Description";

const imgList = [
  require("../../assets/images/01dan.gif").default,
  require("../../assets/images/02gan.gif").default,
  require("../../assets/images/03fei.gif").default,
  require("../../assets/images/04dachang.gif").default,
  require("../../assets/images/05wei.gif").default,
  require("../../assets/images/06pi.gif").default,
  require("../../assets/images/07xin.gif").default,
  require("../../assets/images/08xiaochang.gif").default,
  require("../../assets/images/09pangguang.gif").default,
  require("../../assets/images/10shen.gif").default,
  require("../../assets/images/11xinbao.gif").default,
  require("../../assets/images/12sanjiao.gif").default,
];

const WorkTable = () => {
  const [meridian, setMeridian] = useState(data[0]);
  const [currentTime, setCurrentTime] = useState(
    dayjs().format("YYYY年MM月DD日 hh:mm:ss")
  );
  // * 根据当前时间跳转对应时令
  const getMeridianByTime = () => {
    // * 初始获取当前时间，计算渲染区间
    const currentHour = dayjs().get("hour");
    const target = data.find((item) => item.index.includes(currentHour));
    console.log(currentHour, target, "当前时间阶段");
    setMeridian(target as any);
  };
  useEffect(() => {
    getMeridianByTime();
  }, []);
  setInterval(() => {
    setCurrentTime(dayjs().format("YYYY年MM月DD日 HH:mm:ss"));
  }, 1000);
  // * 当令跳转
  const gotoMeridian = (type: "prev" | "next" | "reset") => {
    switch (type) {
      case "prev":
        if (meridian.i === 0) {
          setMeridian(data[data.length - 1]);
          return;
        }
        setMeridian(data[meridian.i - 1]);
        break;
      case "next":
        if (meridian.i === data.length - 1) {
          setMeridian(data[0]);
          return;
        }
        setMeridian(data[meridian.i + 1]);
        break;
      case "reset":
        getMeridianByTime();
        break;
      default:
        return;
    }
  };
  return (
    <Card title="12经络流注" className="wrapper">
      <Row className="cardWrap" gutter={[20, 20]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card title={<div>当前时间：{currentTime}</div>} className="leftWrap">
            <Description title="时间段" content={meridian.time} />
            <Description title="当令" content={meridian.meridian} />
            <Description title="详情" content={meridian.detail} />

            <div className="detail-wrap">
              <div className="detailTitle">作用：</div>
              <div
                className="detailContent"
                dangerouslySetInnerHTML={{
                  __html: effectList[meridian.i].effect,
                }}
              ></div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card className="rightWrap">
            <img className="rightImg" src={imgList[meridian.i]} alt="" />
          </Card>
        </Col>
      </Row>
      <div className="control">
        <Button type="primary" onClick={() => gotoMeridian("prev")}>
          上一当令
        </Button>
        <Button type="primary" onClick={() => gotoMeridian("reset")}>
          回到当前
        </Button>
        <Button type="primary" onClick={() => gotoMeridian("next")}>
          下一当令
        </Button>
      </div>
    </Card>
  );
};

export default WorkTable;
